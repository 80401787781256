import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import BackgroundMap from '../components/BackgroundMap';
import { Header } from '../components/Header';
import Workplace from '../assets/images/ActivityCardWorkplace.png';
import Baber from '../assets/images/ActivityCardBarber.png';
import { Calendar, CalendarSVG } from '../assets/icons/Calendar';
import { House, HouseSVG } from '../assets/icons/House';
import { Music, MusicSVG } from '../assets/icons/Music';
import { WorkPin, WorkPinSVG } from '../assets/icons/WorkPin';
import {
  AdvertisementPin,
  AdvertisementPinSVG,
} from '../assets/icons/AdvertisementPin';
import { CalendarPin, CalendarPinSVG } from '../assets/icons/CalendarPin';
import {
  RedDotBigRedCirle,
  RedDotBigRedCirleSVG,
} from '../assets/icons/RedDotBigRedCirle';
import { BlueDot, BlueDotSVG } from '../assets/icons/BlueDot';
import { TextSection } from '../components/TextSection';
import { ActivityCard } from '../components/ActivityCard';
import { serializers } from '../utils/serializer';
import { TextContainer } from '../components/TextContainer';
import { ImageMapUpsideDown } from '../components/BackgroundMapUpsideDown';
import {
  container,
  containerPage,
  contentContainer,
  contentItem,
  item,
  itemPage,
} from '../utils/framer-motion-config';

export default function WhatDoesItSolve({ data }) {
  const sanityContent = data.whatItSolves.contentSections;
  const LastPartOfSanityContent = sanityContent.slice(1);

  return (
    <WhatDoesItSolveContainer
      variants={containerPage}
      initial="hidden"
      animate="show"
    >
      <ImageMapUpsideDown />

      <WhatDoesItSolveContent variants={itemPage}>
        <TextContainer>
          {sanityContent[0] && (
            <TextSection>
              <Header big bolder>
                {sanityContent[0].title}
              </Header>

              <SanityBlockContent
                blocks={sanityContent[0]._rawText}
                serializers={serializers}
                projectId={process.env.SANITY_PROJECT_ID}
                dataset={process.env.SANITY_DATASET}
              />
            </TextSection>
          )}

          {LastPartOfSanityContent.map((content, index) => (
            <TextSection key={index}>
              <Header>{content.title}</Header>
              <SanityBlockContent
                blocks={content._rawText}
                serializers={serializers}
                projectId={process.env.SANITY_PROJECT_ID}
                dataset={process.env.SANITY_DATASET}
              />
            </TextSection>
          ))}
        </TextContainer>

        <Calendar size={89} variants={contentItem} />
        <House size={89} variants={contentItem} />
      </WhatDoesItSolveContent>

      <BackgroundMapCustom
        color="var(--lightGrey)"
        variants={container}
        imageStyle="cover"
        image
        overlay
      >
        <BlueDot size={58} variants={item} />
        <RedDotBigRedCirle size={34} variants={item} />

        <WorkPin variants={item} />
        <CalendarPin variants={item} />
        <AdvertisementPin variants={item} />
        <Music size={46} variants={item} />

        <ActivityCardWorkplace src={Workplace} alt="" variants={item} />
        <ActivityCardBarber src={Baber} alt="" variants={item} />
      </BackgroundMapCustom>
    </WhatDoesItSolveContainer>
  );
}

export const query = graphql`
  query WhatItSolvesQuery {
    whatItSolves: sanityPage(
      id: { eq: "-e0b4a016-be69-5d48-ae01-0c905fc3645e" }
    ) {
      contentSections {
        ... on SanityTextSection {
          title
          subtitle
          _rawText
        }
      }
    }
  }
`;

const ActivityCardWorkplace = styled(ActivityCard)``;

const ActivityCardBarber = styled(ActivityCard)``;

const WhatDoesItSolveContainer = styled(motion.div)`
  background-color: var(--lightGrey);
  transition: 0.2s ease-in-out;
`;

const WhatDoesItSolveContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  max-width: var(--gridWidth);
  margin: 0 auto;
  padding: 3rem;
  width: 100%;
  position: relative;
  z-index: 60;

  ${CalendarSVG} {
    display: none;
  }

  ${HouseSVG} {
    display: none;
  }

  @media (min-width: 1080px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto 10rem auto;
    padding: 8.6rem 0 2rem 0;

    ${CalendarSVG} {
      display: block;
      position: absolute;
      bottom: 3%;
      right: 25%;
    }

    ${HouseSVG} {
      display: block;
      position: absolute;
      top: 30%;
      right: 0;
    }
  }
`;

const BackgroundMapCustom = styled(BackgroundMap)`
  ${AdvertisementPinSVG} {
    height: auto;
    width: 60px;
    z-index: 10;
    grid-column: 4;
    grid-row: 8;
  }

  ${BlueDotSVG} {
    z-index: 10;
    grid-column: 3;
    grid-row: 11;
  }

  ${RedDotBigRedCirleSVG} {
    z-index: 10;
    grid-column: 7;
    grid-row: 10;
  }

  ${WorkPinSVG} {
    height: auto;
    width: 60px;
    z-index: 10;
    grid-column: 3;
    grid-row: 4;
  }

  ${CalendarPinSVG} {
    height: auto;
    width: 70px;
    z-index: 10;
    grid-column: 8;
    grid-row: 6;
  }

  ${ActivityCardWorkplace} {
    z-index: 10;
    grid-column: 4 / 8;
    grid-row: 1 / 3;
  }

  ${ActivityCardBarber} {
    z-index: 12;
    grid-column: 7 / 11;
    grid-row: 10 / 12;
  }

  ${MusicSVG} {
    z-index: 12;
    grid-column: 8;
    grid-row: 3;
  }

  @media (min-width: 1080px) {
    ${AdvertisementPinSVG} {
      height: auto;
      width: 98px;
      z-index: 10;
      grid-column: 4;
      grid-row: 9;
    }

    ${BlueDotSVG} {
      z-index: 10;
      grid-column: 2;
      grid-row: 12;
    }

    ${RedDotBigRedCirleSVG} {
      z-index: 10;
      grid-column: 13;
      grid-row: 10;
    }

    ${WorkPinSVG} {
      height: auto;
      width: 98px;
      z-index: 9;
      grid-column: 3;
      grid-row: 5;
      margin-right: -3rem;
    }

    ${CalendarPinSVG} {
      height: auto;
      width: 166px;
      z-index: 10;
      grid-column: 7;
      grid-row: 9;
    }

    ${ActivityCardWorkplace} {
      z-index: 10;
      grid-column: 4 / 7;
      grid-row: 1 / 3;
    }

    ${ActivityCardBarber} {
      z-index: 12;
      grid-column: 8 / 13;
      grid-row: 4 / 9;
      margin-left: -5rem;
    }

    ${MusicSVG} {
      z-index: 12;
      grid-column: 13;
      grid-row: 3;
    }
  }
`;
