import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const Music = ({ size, className, column, row, variants }) => (
  <MusicSVG
    width={size}
    height={size}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    column={column}
    row={row}
    variants={variants}
  >
    <g opacity=".5" fill="#fff">
      <circle cx="23" cy="23" r="23" />
      <circle cx="23" cy="23" r="23" />
      <circle cx="23" cy="23" r="23" />
    </g>
    <circle cx="23" cy="23" r="15" fill="#fff" />
    <circle cx="23" cy="23" r="15" fill="#fff" />
    <circle cx="23" cy="23" r="15" fill="#fff" />
    <g>
      <path
        d="M19.363 16.969l-1.45 9.161 1.988.315 1.45-9.161-1.988-.315zM29.537 18.583l-1.45 9.161 1.988.315 1.45-9.161-1.988-.315z"
        fill="#017AFF"
      />
      <path
        d="M19.839 14l-.478 2.97 1.987.315 8.187 1.296 1.988.315.478-2.97L19.839 14z"
        fill="#FF6696"
      />
      <path
        d="M19.901 26.46a3.465 3.465 0 01-1.383 2.265 3.234 3.234 0 01-1.232.566c-.444.11-.906.128-1.357.05-.45-.071-.881-.23-1.27-.465a3.467 3.467 0 01-.994-.919 3.24 3.24 0 01-.566-1.233 3.306 3.306 0 01-.05-1.358c.071-.45.23-.882.465-1.271.238-.389.55-.727.918-.994a3.24 3.24 0 011.233-.567 3.3 3.3 0 011.357-.05c.45.071.88.23 1.27.466.388.238.726.55.994.919.273.366.466.787.566 1.233.11.444.127.906.05 1.357zM30.076 28.07a3.52 3.52 0 01-.466 1.272 3.47 3.47 0 01-.918.994 3.236 3.236 0 01-1.232.566 3.3 3.3 0 01-1.358.05c-.449-.07-.88-.229-1.27-.465a3.465 3.465 0 01-.993-.919 3.24 3.24 0 01-.566-1.233 3.307 3.307 0 01-.05-1.358c.071-.45.229-.882.465-1.272.238-.388.55-.726.918-.994a3.236 3.236 0 011.233-.566 3.3 3.3 0 011.357-.05c.45.071.88.23 1.27.465.388.238.726.55.993.919.274.366.467.787.566 1.233.098.446.116.907.05 1.358z"
        fill="#74E2BF"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" transform="translate(13 14)" d="M0 0h19v17H0z" />
      </clipPath>
    </defs>
  </MusicSVG>
);

export const MusicSVG = styled(motion.svg)`
  grid-column: ${(props) => (props.column ? props.column : '')};
  grid-row: ${(props) => (props.row ? props.row : '')};
  z-index: 120;
`;
