import React from 'react';
import styled from 'styled-components';

import MapUpsideDown from '../../static/Map_upside-down.png';

export const ImageMapUpsideDown = ({ className }) => (
  <ImageMapUpsideDownStyles
    className={className}
    src={MapUpsideDown}
    id="roads"
    alt="barcode in Oslo"
  />
);

export const ImageMapUpsideDownStyles = styled.img`
  position: absolute;
  top: 7rem;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 1600px;
  margin: 0 auto;

  object-fit: cover;
  width: 100%;
  background-color: ${(props) =>
    props.imageColor ? props.imageColor : 'none'};
`;
