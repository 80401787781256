import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const House = ({ size, className, variants }) => (
  <HouseSVG
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 89 89"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <g opacity=".3" fill="#fff">
      <circle cx="44.5" cy="44.5" r="44.5" />
      <circle cx="44.5" cy="44.5" r="44.5" />
      <circle cx="44.5" cy="44.5" r="44.5" />
    </g>
    <circle cx="44.5" cy="44.5" r="25.5" fill="#0071EB" />
    <g>
      <path d="M58.093 40.106H31.925v16.88h26.168v-16.88z" fill="#fff" />
      <path d="M28 40.106L45.008 29 62 40.106H28z" fill="#FF6696" />
      <path d="M37.95 46.443V57h6.506V46.443H37.95z" fill="#74E2BE" />
      <path d="M37.94 54.028v-7.581h6.506l-6.506 7.581z" fill="#007AFF" />
      <path
        d="M51.26 49.282c1.606 0 2.908-1.27 2.908-2.834 0-1.566-1.302-2.835-2.908-2.835-1.606 0-2.907 1.27-2.907 2.835s1.301 2.834 2.907 2.834z"
        fill="#FF6696"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" transform="translate(28 29)" d="M0 0h34v28H0z" />
      </clipPath>
    </defs>
  </HouseSVG>
);

export const HouseSVG = styled(motion.svg)`
  z-index: 120;
`;
