import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const TextContainer = ({ children, noOverlay }) => (
  <>
    <TextContainerStyles>
      {children}
      {!noOverlay && <Overlay />}
    </TextContainerStyles>
  </>
);

const TextContainerStyles = styled(motion.div)`
  max-width: 581px;
  margin: 0 auto 5rem auto;
  background-color: var(--white);
  padding: 3rem 2rem;
  border-radius: 1.9rem;
  z-index: 100;
  position: relative;

  > * {
    z-index: 50;
  }

  @media (min-width: 1024px) {
    padding: 6rem;
  }
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  max-height: ${(props) =>
    props.imageHeight ? `${props.imageHeight}px` : '600px'};

  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(239, 242, 249, 1) 0%,
    rgba(239, 242, 249, 0) 40%
  );

  @media (min-width: 1024px) {
    min-height: 900px;
    max-width: var(--maxGridWidth);
    margin: 0 auto;
  }
`;
