import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const Calendar = ({ size, className, variants }) => (
  <CalendarSVG
    width={size}
    height={size}
    viewBox="0 0 89 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <g opacity=".4" fill="#fff">
      <circle cx="44.5" cy="44.5" r="44.5" />
      <circle cx="44.5" cy="44.5" r="44.5" />
      <circle cx="44.5" cy="44.5" r="44.5" />
    </g>
    <circle cx="44" cy="45" r="30" fill="#74E2BE" />
    <path d="M58 41.161H31V59h27V41.161z" fill="#fff" />
    <path d="M58 33.032H31v8.13h27v-8.13z" fill="#FF6696" />
    <path
      d="M39.237 31h-3.203v6.097h3.203V31zM52.966 31h-3.204v6.097h3.204V31z"
      fill="#017AFF"
    />
    <path
      d="M43.333 48.388l-1.35 1.016-1.282-1.965 3.204-2.213h2.128v9.755h-2.7v-6.593z"
      fill="#007AFF"
    />
  </CalendarSVG>
);

export const CalendarSVG = styled(motion.svg)`
  z-index: 120;
`;
