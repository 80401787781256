import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const CalendarPin = ({ className, variants }) => (
  <CalendarPinSVG
    width="513"
    height="809"
    viewBox="0 0 513 809"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <g filter="url(#a)">
      <ellipse
        cx="256"
        cy="685.5"
        rx="179"
        ry="49.5"
        fill="#000"
        fillOpacity=".2"
      />
    </g>
    <g filter="url(#b)">
      <path
        d="M4 36C4 16.118 20.118 0 40 0h433c19.882 0 36 16.118 36 36v491.033c0 19.882-16.118 36-36 36H344.7a6.002 6.002 0 0 0-4.698 2.268L249.676 679l-76.695-113.33a6 6 0 0 0-4.969-2.637H40c-19.882 0-36-16.117-36-36V36Z"
        fill="#45C89D"
      />
      <path
        d="M40 4h433c17.673 0 32 14.327 32 32v491.033c0 17.673-14.327 32-32 32H344.7a10.003 10.003 0 0 0-7.83 3.78l-86.936 109.432-73.641-108.816a9.998 9.998 0 0 0-8.281-4.396H40c-17.673 0-32-14.327-32-32V36C8 18.327 22.327 4 40 4Z"
        stroke="#fff"
        strokeWidth="8"
      />
    </g>
    <path
      d="M143.22 408.668V455h-11.286v-19.074h-17.556V455h-11.286v-46.332h11.286v18.15h17.556v-18.15h11.286ZM167.933 444.44l7.986-26.268h12.012L174.863 455h-13.926l-13.068-36.828h12.078l7.986 26.268ZM190.438 436.52c0-3.784.704-7.106 2.112-9.966 1.452-2.86 3.41-5.06 5.874-6.6 2.464-1.54 5.214-2.31 8.25-2.31 2.596 0 4.862.528 6.798 1.584 1.98 1.056 3.498 2.442 4.554 4.158v-5.214h11.286V455h-11.286v-5.214c-1.1 1.716-2.64 3.102-4.62 4.158-1.936 1.056-4.202 1.584-6.798 1.584-2.992 0-5.72-.77-8.184-2.31-2.464-1.584-4.422-3.806-5.874-6.666-1.408-2.904-2.112-6.248-2.112-10.032Zm27.588.066c0-2.816-.792-5.038-2.376-6.666-1.54-1.628-3.432-2.442-5.676-2.442s-4.158.814-5.742 2.442c-1.54 1.584-2.31 3.784-2.31 6.6s.77 5.06 2.31 6.732c1.584 1.628 3.498 2.442 5.742 2.442 2.244 0 4.136-.814 5.676-2.442 1.584-1.628 2.376-3.85 2.376-6.666ZM266.445 455.528c-3.212 0-6.072-.55-8.58-1.65-2.508-1.1-4.488-2.596-5.94-4.488-1.452-1.936-2.266-4.092-2.442-6.468h11.154c.132 1.276.726 2.31 1.782 3.102 1.056.792 2.354 1.188 3.894 1.188 1.408 0 2.486-.264 3.234-.792.792-.572 1.188-1.298 1.188-2.178 0-1.056-.55-1.826-1.65-2.31-1.1-.528-2.882-1.1-5.346-1.716-2.64-.616-4.84-1.254-6.6-1.914a11.85 11.85 0 0 1-4.554-3.234c-1.276-1.496-1.914-3.498-1.914-6.006 0-2.112.572-4.026 1.716-5.742 1.188-1.76 2.904-3.146 5.148-4.158 2.288-1.012 4.994-1.518 8.118-1.518 4.62 0 8.25 1.144 10.89 3.432 2.684 2.288 4.224 5.324 4.62 9.108h-10.428c-.176-1.276-.748-2.288-1.716-3.036-.924-.748-2.156-1.122-3.696-1.122-1.32 0-2.332.264-3.036.792-.704.484-1.056 1.166-1.056 2.046 0 1.056.55 1.848 1.65 2.376 1.144.528 2.904 1.056 5.28 1.584 2.728.704 4.95 1.408 6.666 2.112 1.716.66 3.212 1.76 4.488 3.3 1.32 1.496 2.002 3.52 2.046 6.072 0 2.156-.616 4.092-1.848 5.808-1.188 1.672-2.926 2.992-5.214 3.96-2.244.968-4.862 1.452-7.854 1.452ZM310.772 455l-11.22-15.444V455h-11.286v-48.84h11.286v26.994l11.154-14.982h13.926l-15.312 18.48L324.764 455h-13.992Z"
      fill="#fff"
    />
    <path
      d="M334.675 414.344c-1.98 0-3.608-.572-4.884-1.716-1.232-1.188-1.848-2.64-1.848-4.356 0-1.76.616-3.212 1.848-4.356 1.276-1.188 2.904-1.782 4.884-1.782 1.936 0 3.52.594 4.752 1.782 1.276 1.144 1.914 2.596 1.914 4.356 0 1.716-.638 3.168-1.914 4.356-1.232 1.144-2.816 1.716-4.752 1.716Zm5.676 45.408c0 4.532-1.144 7.788-3.432 9.768-2.288 2.024-5.478 3.036-9.57 3.036h-4.422v-9.57h2.706c1.232 0 2.112-.242 2.64-.726s.792-1.276.792-2.376v-41.712h11.286v41.58ZM383.181 435.992a28.81 28.81 0 0 1-.198 3.3h-25.542c.176 2.288.902 4.048 2.178 5.28 1.32 1.188 2.926 1.782 4.818 1.782 2.816 0 4.774-1.188 5.874-3.564h12.012a16.27 16.27 0 0 1-3.366 6.534c-1.584 1.936-3.586 3.454-6.006 4.554-2.42 1.1-5.126 1.65-8.118 1.65-3.608 0-6.82-.77-9.636-2.31-2.816-1.54-5.016-3.74-6.6-6.6-1.584-2.86-2.376-6.204-2.376-10.032 0-3.828.77-7.172 2.31-10.032 1.584-2.86 3.784-5.06 6.6-6.6s6.05-2.31 9.702-2.31c3.564 0 6.732.748 9.504 2.244 2.772 1.496 4.928 3.63 6.468 6.402 1.584 2.772 2.376 6.006 2.376 9.702Zm-11.55-2.97c0-1.936-.66-3.476-1.98-4.62-1.32-1.144-2.97-1.716-4.95-1.716-1.892 0-3.498.55-4.818 1.65-1.276 1.1-2.068 2.662-2.376 4.686h14.124ZM400.421 424.31c1.32-2.024 2.97-3.608 4.95-4.752 1.98-1.188 4.18-1.782 6.6-1.782v11.946h-3.102c-2.816 0-4.928.616-6.336 1.848-1.408 1.188-2.112 3.3-2.112 6.336V455h-11.286v-36.828h11.286v6.138Z"
      fill="#fff"
    />
    <g filter="url(#c)">
      <path d="M347.324 174.658H167V295.4h180.324V174.658Z" fill="#fff" />
      <path d="M347.324 119.806H167v54.847h180.324v-54.847Z" fill="#F06594" />
      <path
        d="M221.843 106h-21.307v41.431h21.307V106ZM313.387 106H292.08v41.431h21.307V106Z"
        fill="#84CDB5"
      />
      <path
        d="m249.077 223.576-9.075 7.103-8.681-13.416 21.702-14.994h14.205v66.29h-18.151v-44.983Z"
        fill="#4274B9"
      />
    </g>
    <defs>
      <filter
        id="a"
        x="3"
        y="562"
        width="506"
        height="247"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="37" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="b"
        x="0"
        y="0"
        width="513"
        height="687"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="c"
        x="123"
        y="66"
        width="268.324"
        height="277.4"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="22" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </CalendarPinSVG>
);

export const CalendarPinSVG = styled(motion.svg)`
  z-index: 120;
`;
